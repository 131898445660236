import { z } from "zod";

import { GIFT_CARD_MAX_AMOUNT, GIFT_CARD_MIN_AMOUNT } from "../constants";

export const emailValidator = z
  .string()
  .min(1, "Please enter a valid email address")
  .email("Please enter a valid email address");

export const passwordRequirements = [
  { pattern: /^.{10,20}$/, message: "Must be 10-20 characters long" },
  { pattern: /\d/, message: "Include at least one number" },
  { pattern: /[A-Z]/, message: "Include at least one uppercase letter" },
  { pattern: /[a-z]/, message: "Include at least one lowercase letter" },
  {
    pattern: /[!@#$%^&*(),.?":{}|<>]/,
    message: "Include at least one special character",
  },
];

export const passwordValidator = passwordRequirements.reduce(
  (validator, { pattern, message }) => {
    return validator.regex(pattern, message);
  },
  z.string(),
);

export const confirmPasswordValidator = () => {
  return {
    message: "Password and password confirmation do not match",
    path: ["confirmPassword"],
  };
};

export const confirmInputPasswordValidator = () => {
  return {
    message: "New password must be different from current password!",
    path: ["confirmPassword"],
  };
};

export const newPasswordValidator = () => {
  return {
    message: "New password must be different from current password!",
    path: ["password"],
  };
};

export const birthDayValidator = z
  .string()
  .optional()
  .superRefine((val, ctx) => {
    if (val && val !== "undefined") {
      const date = new Date(val);
      if (isNaN(date.getTime())) {
        ctx.addIssue({ code: "custom", message: "Please enter a valid date." });
        return;
      }
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (date > today) {
        ctx.addIssue({ code: "custom", message: "Please enter a valid date." });
      }
    }
  })
  .transform(val => {
    if (val && val !== "invalid") {
      const [year, month, day] = val.split("-");
      return `${day}/${month}/${year}`;
    }
    return val;
  });
export const giftCardAmountBaseValidator = z
  .string()
  .min(1, {
    message: "This field is required",
  })
  .transform(val => {
    const num = Number(val);
    if (isNaN(num)) {
      throw new Error("Please enter a valid amount");
    }
    return num;
  })
  .refine(num => num <= GIFT_CARD_MAX_AMOUNT, {
    message: "Please enter a valid amount",
  });

export const giftCardAmountValidator = giftCardAmountBaseValidator.refine(
  num => num >= GIFT_CARD_MIN_AMOUNT,
  {
    message: "Please enter a valid amount",
  },
);

export const senderNameValidator = z
  .string()
  .min(1, "Please enter the sender's name");

export const recipientNameValidator = z
  .string()
  .min(1, "Please enter a recipient's name");

export const messageValidator = z.string().optional();
export const phoneValidator = z
  .string({
    errorMap: () => ({
      message:
        "Please enter a valid home or mobile number. Be sure to include the area code if you are using a landline.",
    }),
  })
  .regex(/^[0-9]+$/, {
    message: "Please enter a valid 9 or 10-digit mobile number.",
  })
  .max(10, {
    message: "Please enter a valid 9 or 10-digit mobile number.",
  })
  .min(9, {
    message: "Please enter a valid 9 or 10-digit mobile number.",
  });
